.docs-header {
    grid-row: 2;
    grid-column: 1 /span 2;

    @include breakpoint(setting('breakpoint.small')) {
        grid-row: 3;
        grid-column: 1;
        width: 250px;
        background-color: color(blue);
    }

    .docs-nav {

        @include breakpoint(setting('breakpoint.small')) {
            background-color: color(blue);
            padding: 0;
        }
    }

    .docs-global-header {
        max-height: 85px;
        overflow-y: hidden;
        position: relative;
        z-index: 50;

        @include breakpoint(setting('breakpoint.small')) {
            overflow-y: visible;
            height: 100vh;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
        }
    
        &.-expanded {
            background-color: color(gray, xlight);
            max-height: 1000px;
            position: relative;

            @include breakpoint(setting('breakpoint.small')) {
                background-color: color(blue);
                }
                
            .primary-nav > li &.active {
                display: block;
            }
        
            + main {
                opacity: 0.5;
            }
        }
    
        > .container {
        height: 100%;
        }
    }

    .burger {
        position: relative;
        float: right;
        height: 0;
        padding: 25px 10px 10px 10px;
        z-index: 100;
        grid-column: 1;
        align-self: start;
        max-width: 100px;
        display: block;
        color: color(gray, xlight);

        @include breakpoint(setting('breakpoint.small')) {
            display: none;
        }
    }

    .primary-nav {

        @include breakpoint(setting('breakpoint.large')) {
            padding-top: 20px;
            padding-right: 1em;
            padding-bottom: 1em;
        }

        > .primary-nav__item {

            @include breakpoint(setting('breakpoint.large')) {
                float: none;
                position: relative;
            }
        }
    }

    .-docs-nav-logo {
        display: block;
        height: 80px;

        @include breakpoint(setting('breakpoint.small')) {
            display: none;
        }
    }

    .nav-menu {
        background-color: transparent;
        border-bottom: none;

        @include breakpoint(setting('breakpoint.small')) {
            min-width: 0;
        }

        @include breakpoint(setting('breakpoint.large')) {
          border: none;
          position: relative;
            
            &:not(.edge):before {
                content: none;
            }
        
            &:not(.edge):after {
                content: none;
            }
        
            &.edge {
                right: 0;
        
                &:before {
                content: none;
                }
        
                &:after {
                content: none;
                }
            }
        }
    }


    .primary-nav > li:hover & {
        color: lighten(color(blue), 5%);
    }

    .nav-link {

        @include breakpoint(setting('breakpoint.large')) {
            text-align: left;
        }
    
        &.-primary {
            color: color(blue);

            @include breakpoint(setting('breakpoint.small')) {
                color: color(gray, light);
            }

            @include breakpoint(setting('breakpoint.large')) {
                padding: 1em;
            }

            .primary-nav.expanded & {
                color: color(gray, light);
        
                &:hover {
                    color: color(gray, xlight);
                }
            }
        }
    
        i {
            display: none;

            @include breakpoint(setting('breakpoint.large')) {
                display: block;
            }
        }
    
        .primary-nav > li:hover &.-active,
        .primary-nav > li:hover &:hover,
        .primary-nav > li:hover &:active,
        .primary-nav > li:hover &:focus {
            color: color(gray, xlight);
        }
    
        &.-dropdown-link {
            color: color(gray, xlight);
        }
    
        .primary-nav & {

            @include breakpoint(setting('breakpoint.large')) {
                text-align: left;
            }
        }

        &.-active,
        &:hover,
        &:active,
        &:focus {

            @include breakpoint(setting('breakpoint.large')) {
                color: color(gray, xlight);
            }
        }
    }

    .nav-link-button {
        color: color(blue);
        cursor: pointer;

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, light);
        }

        @include breakpoint(setting('breakpoint.large')) {
            display: block;
        }

        &:hover {
            color: blue;

            @include breakpoint(setting('breakpoint.small')) {
                color: color(gray, xlight);
            }
        }
    }

    .docs-nav h2 {
        font-size: 1.2em;
        padding-top: 2em;
        padding-left: 1em;
        color: color(gray, dark);

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, xlight);
            grid-row: 2;
        }
    }

    .docs-nav li {
        list-style: none;
        text-align: left;
        padding-left: 1.25em;

        @include breakpoint(setting('breakpoint.small')) {
            padding-left: 0;
        }
    }

    .docs-nav a {
        font-size: .9em;
        text-decoration: none;
        color: color(gray, dark);

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, light);
        }
    }

    ul {
        padding-right: 1em;
        padding-bottom: 1em;
        background-color: color(gray, xlight);

        @include breakpoint(setting('breakpoint.small')) {
            background-color: color(blue);
            grid-row: 3;
        }
    }

    .-docs-head-logo {
        display: block;

        @include breakpoint(setting('breakpoint.small')) {
            height: 85px;
        }
    }

    .-docs-head-logo a {
        display: block;
        height: 80px;

        @include breakpoint(setting('breakpoint.small')) {
            display: none;
        }
    }

    .global-header .primary-nav {
        padding-top: 20px;

        @include breakpoint(setting('breakpoint.large')) {
            float: none;
            padding-right: 1em;
        }
    }

    .primary-nav .primary-nav__item {
        border-bottom: 1px solid color(blue);

        @include breakpoint(setting('breakpoint.small')) {
            border-bottom: 1px solid color(gray, xlight);
        }

        @include breakpoint(setting('breakpoint.large')) {
            float: none;
        }
    }

    .primary-nav__item:hover a {

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, light);
        }
    }

    .primary-nav > li:hover .nav-link.-primary {

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, xlight);
        }
    }

    .nav-menu li:hover a {

        @include breakpoint(setting('breakpoint.small')) {
            color: color(gray, xlight);
        }
    }

    .primary-nav__item {
        max-width: 100vw;
    }
}