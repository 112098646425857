.featured-entry {
  color: white;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include clearfix;
}

.featured-entry__grid {
  @extend .-featured-entry-grid;
  height: 100%;
  align-items: center;
  padding: setting('gutterPadding') 0;

  @include breakpoint(setting('breakpoint.large')) {
    padding: setting('gutterPadding') 90px;
  }
}

.featured-entry__content {
  position: relative;
  height: 100%;
  z-index: 20;
  text-align: center;

  @include breakpoint(setting('breakpoint.medium')) {
    text-align: right;
  }
}

.featured-entry__content__headline p,
p.featured-entry__content__headline {
  font-family: $baskerville;
  font-style: italic;
  font-size: 1.188em;
  line-height: 1;
}

.featured-entry__image.-svg {
  height: 100%;
}

.featured-entry__cta__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button {
    @include breakpoint(setting('breakpoint.medium')) {
      align-self: flex-end;
    }
  }
}