.global-header {
  background-color: white;
  max-height: setting('global.header.height.medium');
  overflow-y: hidden;
  position: relative;
  transition: max-height .5s ease, background-color .5s ease;
  z-index: 50;

  @include breakpoint(setting('breakpoint.large')) {
    height: setting('global.header.height.large');
    max-height: setting('global.header.height.large');
    overflow-y: visible;
  }

  &.-expanded{
    background-color: white;
    max-height: 1000px;
    position: relative;

    .primary-nav > li &.active {
      display: block;
    }

    + main {
      opacity: 0.5;
    }
  }

  > .container {
    height: 100%;
  }
}
