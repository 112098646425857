$event: (
  breakpoint: (
    xlarge: em(1200px)
  ),
  height: 355px,
  padding: (
    bottom: 8.75%,
    left: 11%,
    top: 6.25%,
    right: 11%
  )
);

.event__list {
  &:not(.-grid) {
    .event-item:not(:first-child) {
      border-top: 1px solid #979797;
    }

    .event {
      @extend .-listing-grid;

      &:hover {
        background-color: darken(color(grey, xlight), 5%);
      }
    }

    .event__arrow {
      display: none;
    }
  }

  &.-grid {
    width: 100%;
    @extend .grid;
    @extend .-col3-1;

    .event__description {
      display: none;
    }

    .event {
      margin-top: setting('gutterPaddingSmall');
      padding: 0;

      &:hover {
        .event__arrow {
          right: 10px;
        }
      }
    }

    img {
      width: 100%;
    }

    .button {
      display: none;
    }

    .-promo {
      padding: 0 10px;
    }

    .event__meta {
      padding-right: 50px;
    }
  }
}

.event {
  @include transition(background-color);
  align-items: center;
  color: color(blue);
  padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');
  position: relative;
  text-decoration: none;

  @include breakpoint(setting('breakpoint.large')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }

  > p {
    font-size: 0.813em;
  }

}

.event__image {
  justify-self: center;

  @include breakpoint(setting('breakpoint.medium')) {
    justify-self: auto;
  }
}

.event__title {
  font-family: $openSans;
  font-size: 1.125em;
  font-weight: 700;
  //line-height: 1.15;

  //@include breakpoint(setting('breakpoint.medium')) {
  //  font-size: 1.75em;
  //  line-height: 1.1;
  //}
  //
  //@include breakpoint(setting('breakpoint.large')) {
  //  font-size: 1.875em;
  //}
  //
  //@include breakpoint(setting('breakpoint.xlarge', $event)) {
  //  font-size: 2.125em;
  //  line-height: 1.15;
  //}
}

.event__meta {
  font-family: $baskerville;
  font-size: .938em;
  font-style: italic;
  position: relative;

  p {
    margin: 0;
  }
}

.event__button {

}

.event__arrow {
  @include transition(right);
  color: color(purple);
  font-size: 64px;
  position: absolute;
  right: 20px;
  top: -20px;
}

.event__cta {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: setting('gutterPaddingSmall');

  &.-promo {
    background-color: color(orange);

  }

  p {
    color: color(grey, xlight);
    font-family: $baskerville;
    font-size: 1.125em;
    font-style: italic;
    margin: .5em 0;
    text-align: center;
  }
}
