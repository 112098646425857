//TODO: get font and size when I get comps.
.page-title {
  color: color(gray, xdark);
  font-family: $openSans;
  font-size: 1.75em;
  font-weight: 400;
  letter-spacing: .5em;
  text-transform: uppercase;

  @include breakpoint(setting('breakpoint.medium')) {
    font-size: 2em;
  }

  @include breakpoint(setting('breakpoint.large')) {
    font-size: 2.125em;
  }

  &.-small {
    font-size: 1.625em;
  }

  &.-white {
    color: white;
  }

  &.-centered {
    text-align: center;
  }

  &.-padded {
    margin: 3em 0;
  }

  &.-padded-top {
    margin: 3em 0 0;
  }

  &.-padded-bottom {
    margin: 0 0 3em;
  }
}
