.-docs {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    
    @include breakpoint(setting('breakpoint.small')) {
        grid-template-columns: auto 1fr;
    }

    .main {
        grid-row: 3;
        grid-column: 1;

        @include breakpoint(setting('breakpoint.small')) {
            grid-column: 2;
        }
    }

    .logo-bar {
        background-color: color(gray, xlight);
        display: none;

        @include breakpoint(setting('breakpoint.small')) {
            display: flex;
            text-align: center;
            width: 100%;
            grid-column: 1 /span 2;
            grid-row: 2;
            align-items: center;
        }

        .-docs-head-logo {
            width: 100px;
            padding: 10px;
            height: 100%;
        }
    }

    .global-footer {
        grid-row: 4;
        grid-column: 1;

        @include breakpoint(setting('breakpoint.small')) {
            grid-column: 1 /span 2;
        }
    }

    h1.hero__content__headline {
        font-size: 2em;
        line-height: 1.5em;
        margin: 0.5em;
        display: inline-block;

        @include breakpoint(setting('breakpoint.medium')) {
            font-size: 3em;
            padding: .2em;
        }
    }

    .secondary-nav {
        grid-row: 1;
        grid-column: 1;

        @include breakpoint(setting('breakpoint.small')) {
            grid-column: 1 /span 2;
        }
    }
}