.nav-link-button {
  @include size(52px);
  color: color(gray);
  display: block;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  .global-header & {
    @include breakpoint(setting('breakpoint.large')) {
      display: none;
    }
  }

  &.is-active,
  &:hover,
  &:active,
  &:focus {
    color: color(blue);
    cursor: pointer;
  }

  label {
    margin-bottom: 0;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  i.icon-plus,
  i.icon-minus {
    display: block;
  }

  i.icon-minus {
    display: none;
  }

  i.icon-plus {
    display: block;
  }

  &.is-active {
    i.icon-plus {
      display: none;
    }

    i.icon-minus {
      display: block;
    }

    + .nav-menu nav {
      display: block;
    }
  }

  .primary-nav & {
    z-index: 3;
  }
}
