.global-footer__nav {
  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }

  a {
    color: color(gray, light);
    text-decoration: none;
    transition: color .25s;

    &:hover {
      color: color(gray);
    }
  }
}
