//TODO: responsive font sizes.

h1 {
  color: color(blue);
  font-size: 4.625em;
  text-transform: uppercase;
}

h2, h3 {
  font-family: $openSansCondensed;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  color: color(blue);
  font-size: 1.625em;

  @include breakpoint(setting('breakpoint.small')) {
    font-size: 2.25em;
  }

  @include breakpoint(setting('breakpoint.medium')) {
    font-size: 2.625em;
  }
}

h3 {
  font-size: 1.375em;
}

h2, h3, h4, h5, h6 {
  color: color(blue);
  .article & {
    margin-top: 1.250em;
  }
}

p {
  color: color(blue);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;

  &.lead-paragraph {
    font-size: 1.188rem;
    margin-bottom: 2em;
    margin-top: 1em;
  }

  &.accent {
    font-family: $baskerville;
    font-style: italic;
  }

  &.meta {
    font-size: 0.75em;
  }
}

hr {
  border: 0;
  border-bottom: 1px solid color(gray, xlight);
  height: 0;
  margin: 2em 0;
}

// Ordered Lists

ol ol li {
  list-style-type: lower-alpha;
}

ol ol ol li {
  list-style-type: lower-roman;
}

// Inline Elements

p > a {
  @include link();
  display: inline-block;
}

a {
  @include transition(color);

  color: color(blue);

  &.accent {
    font-family: $baskerville;
    font-style: italic;
  }

  &:hover {
    color: lighten(color(blue), 10%);
  }
}

table {
  color: color(blue);
  font-weight: 300;
  line-height: 1.4;
  font-size: 1rem;
}

figcaption {
  text-align: center;
  color: color(gray, xdark);
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 5px;
  border-bottom: 1px solid color(gray, light);
  display: inline-block;
  padding-bottom: 5px;
}