.judges-directory {
  &.-without-images {
    .judge {
      .judge-image {
        display: none !important;
      }
    }
  }
}

.judges-directory-filters {
  display: flex;
  flex-wrap: wrap;

  button,
  select {
    margin-right: 1.25rem;
  }

  button {
    margin-bottom: 0;
  }

  select {
    padding-left: 1rem;
    width: auto;
    flex: 1;

    &:last-of-type {
      margin-right: 0;
    }
  }

  p {
    width: 100%;
  }
}

.judge-group {
  margin-bottom: 2rem;

  h3 {
    font-size: 2rem;
    margin: 0 0 2rem;
  }
}

.judge {
  .judge-image {
    display: block;
    margin-bottom: 0.5rem;

    img {
      padding: 5px;
      border: 1px solid #ccc;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        border-color: #aaa;        
      }
    }
  }
}

.judge-detail-header {
  display: flex;
  justify-content: space-between;

  > div:first-of-type {
    margin-right: 2rem;
  }

  h2 {
    margin-top: 0;
  }

  .image-wrapper {
    max-width: 40%;

    @include breakpoint(setting('breakpoint.small')) {
      max-width: none;
    }
  }
}