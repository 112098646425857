.breadcrumbs-wrapper {
  position: relative; 
}

.breadcrumb {
  font-size: .75rem;
  font-weight: 500;
  margin: 0 0 2.5em;
  position: relative;
  @include clearfix;

  @include breakpoint(setting('breakpoint.medium')) {
    margin-top: 2.5em;
  }

  // Components

  .breadcrumb__item {
    font-size: .75rem;
    color: color(gray);
    font-weight: 500;
    float: left;
    margin-bottom: 0;

    &:after {
      content: '/';
      padding: 0 4px;
    }

    &.-active {
      color: color(gray);

      &:after {
        content: none;
      }
    }

    a {
      color: color(purple);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
