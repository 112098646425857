.top-arrow {
  background-color: color(gray, light);
  bottom: 3rem;
  color: white;
  cursor: pointer;
  display: none;
  font-size: 1.8em;
  margin: 0;
  opacity: 0.4;
  padding: 0;
  position: fixed;
  right: 3rem;
  transition: .25s opacity;
  z-index: 9999;

  &:hover {
    opacity: 0.65;
  }
}
