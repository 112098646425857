.map-wrapper {
  position: relative;
  overflow: hidden;
}

.gm-style {
  .selected {
    overflow: visible !important;
    opacity: 1 !important;

    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      border: 2px solid #fff;
      display: block;
      padding: 21px 22px;
      position: relative;
      left: -8px;
      top: -8px;
    }
  }
}

#map-listing-info {
  width: 300px;
  min-width: 300px;
  height: 100%;
  background: color(grey, xlight);
  position: absolute;
  z-index: 1;
  border-right: 2px solid color(grey, base);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow: scroll;
  padding: 0 20px;

  .fa-window-close {
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &.active {
    transform: translateX(0);
  }

  p {
    font-size: 0.875em;
    line-height: 1.5;

    &:last-of-type {
      font-size: 0.75em;
    }
  }
}