.primary-nav {
  @include clearfix;

  .global-header & {
    padding-top: 56px;
    position: relative;

    @include breakpoint(setting('breakpoint.large')) {
      background-color: transparent;
      float: right;
      margin-bottom: 0;
      padding-right: 55px;
      padding-top: 0;
      top: 0;
    }
  }

  > .primary-nav__item {
    border-bottom: 1px solid color(gray, dark);
    margin-bottom: 0;
    opacity: 1;
    position: relative;
    transition: opacity .3s;

    &.-inactive {
      opacity: 0;
    }

    @include breakpoint(setting('breakpoint.large')) {
      border-bottom: none;
      float: left;
      position: relative;
    }
  }
}
