.secondary-nav {
  @include clearfix;
  align-items: center;
  background-color: color(blue);
  display: flex;
  height: setting('global.header.borderHeight');
  justify-content: center;
  margin-top: 0;
  padding-top: 0;

  @include breakpoint(setting('breakpoint.small')) {
    justify-content: flex-end;
    padding-right: setting('gutterPadding');
  }

}
