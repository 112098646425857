.global-navigation {
  display: block;
  margin-bottom: 2.5em;
  margin-top: 2px;
  @include clearfix;

  @include breakpoint(setting('breakpoint.large')) {
    margin-bottom: 0;
    margin-top: 0;
  }
}
