.separator {
  &.small {
    min-height: 3em;
  }

  &.medium {
    min-height: 6em;
  }

  &.large {
    min-height: 9em;
  }
}