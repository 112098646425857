.Carousel {
  position: absolute;
  width: 100%;

  .swiper-slide {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      /* Position the image in the middle of its container. */
      top: -9999px;
      right: -9999px;
      bottom: -9999px;
      left: -9999px;
      margin: auto;
      /* The following values determine the exact image behaviour. */
      /* You can simulate background-size: cover/contain/etc.
         by changing between min/max/standard width/height values.
         These values simulate background-size: cover
      */
      min-width: 100%;
      min-height: 100%;
    }
  }

  .swiper-button-prev, 
  .swiper-button-next {
    color: rgba(255,255,255, 0.5);
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
}