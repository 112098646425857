$schedule: (
  breakpoint: (
    xlarge: em(1200px)
  ),
  height: 355px,
  padding: (
    bottom: 8.75%,
    left: 11%,
    top: 6.25%,
    right: 11%
  )
);

.schedule__list {
  .schedule-item:not(:first-child),
  .schedule__heading {
    border-top: 1px solid #979797;
  }

  .schedule {
    @extend .-listing-grid;
  }

  .schedule__arrow {
    display: none;
  }

}

.schedule {
  @include transition(background-color);
  align-items: center;
  color: color(blue);
  padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');
  position: relative;

  @include breakpoint(setting('breakpoint.large')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }

  > p {
    font-size: 0.813em;
  }

}

.schedule__title {
  font-family: $openSans;
  font-size: 1.125em;
  font-weight: 700;
}

.schedule__meta {
  position: relative;

  p {
    margin: 0;
  }

  &.-date {
    font-weight: 700;
  }
}

.schedule__cta {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: setting('gutterPaddingSmall');

  p {
    color: color(grey, xlight);
    font-family: $baskerville;
    font-size: 1.125em;
    font-style: italic;
    margin: .5em 0;
  }
}

.schedule__heading {
  padding-top: 0;
  padding-bottom: 0;
  display: none;

  p {
    font-weight: 700;
    margin: 0;
  }

  @include breakpoint(setting('breakpoint.large')) {
    display: grid;
  }
}