.hero__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 20;
}

h1.hero__content__headline {
  color: white;
  display: block;
  font-size: 2em;
  text-transform: uppercase;
  line-height: 1em;

  @include breakpoint(setting('breakpoint.xsmall')) {
    font-size: 2.5em;
  }

  @include breakpoint(setting('breakpoint.small')) {
    font-size: 3em;
    margin: 0;
  }

  @include breakpoint(setting('breakpoint.medium')) {
    font-size: 3.75em;
  }

  @include breakpoint(setting('breakpoint.large')) {
    font-size: 4.5em;
  }

  @include breakpoint(setting('breakpoint.xlarge')) {
    font-size: 5.25em;
  }
}

h2.hero__content__headline {
  color: white;
  line-height: 50px;
  font-size: 2.25em;
  position: relative;

  @include breakpoint(setting('breakpoint.medium')) {
    line-height: 70px;
    font-size: 3.5em;
  }
}

.hero__content__headline {
  display: inline-block;
  font-family: $openSansCondensed;
  font-weight: 700;
  margin: 0 25px 0 25px;
  padding: 0 10px;

  @include breakpoint(setting('breakpoint.medium')) {
    margin: 0;
  }
}