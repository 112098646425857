.overview  {
  padding-bottom: setting('gutterPadding');

  @include breakpoint(setting('breakpoint.large')) {
    padding-bottom: setting('gutterPaddingLarge');
  }

  &.-small{
    padding-bottom: setting('gutterPaddingSmall');

    @include breakpoint(setting('breakpoint.large')) {
      padding-bottom: setting('gutterPadding');
    }
  }

  p {
    font-size: 1em;
    margin: 0;

    @include breakpoint(setting('breakpoint.small')) {
      font-size: 1.188em;
    }
  }
}