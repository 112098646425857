.resources-title {
    padding-top: 40px;
    padding-bottom: 20px;
}
.event-schedule-callout {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px;
        h2 {
            text-align: center;
            padding-bottom: 10px;
        }
}