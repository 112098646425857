.featured-story {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;

  @include breakpoint(setting('breakpoint.small')) {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
  }

  .overview {
    grid-row: 1;
    grid-column: 1;
    padding-bottom: 40px;
  }

  .featured-story-item {
    flex: auto;
    width: 95%;
    grid-row: 2;
    grid-column: 1;
  }

  .social-callout {
    display: grid;
    text-align: center;
    background-color: white;
    padding: 20px 30px;
    grid-column: 1;
    grid-row: 3;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    margin-top: 20px;
    grid-column-gap: 40px;

    @include breakpoint(setting('breakpoint.xsmall')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }

    @include breakpoint(setting('breakpoint.small')) {
      margin-top: 0;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
      grid-column: 2;
      grid-row: 2;
      align-self: start;
    }

    @include breakpoint(setting('breakpoint.medium')) {
      grid-row: 1 /span 2;
      align-self: stretch;
    } 

    .social-callout-top{
      grid-row: 1;
      grid-column: 1;
    }

    .social-callout-bottom {
      grid-row: 2;
      grid-column: 1;

      @include breakpoint(setting('breakpoint.xsmall')) {
        grid-row: 1;
        grid-column: 2;
      }

      @include breakpoint(setting('breakpoint.small')) {
        grid-column: 1;
        grid-row: 2;
      }

      i {
        min-width: 60px;
        font-size: 2em;
      }
    }
  }
}
