.logo {
  display: block;
  height: setting('global.header.height.medium');
  position: relative;
  z-index: 90;
  height: 85px;
  width: 85px;

  @include breakpoint(setting('breakpoint.large')) {
    height: 100%;
  }

  a {
    display: block;
    height: 100%;
    position: relative;
  }

  img {
    opacity: 1;
    transition: opacity .2s;
    width: 100%;
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &:hover img {
    opacity: .75;
  }

  .global-header & {
    top: 0;
    width: 77px;
    height: 103px;

    @include breakpoint(setting('breakpoint.large')) {
      float: left;
      width: 77px;
    }

    @include breakpoint(setting('breakpoint.xlarge')) {
      width: 77px;
    }
  }
}