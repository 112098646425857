.rss-wrapper {
  position: relative;
  font-size: 0.875em;
  display: inline-block;
  top: 0;
  right: 0;

  + .breadcrumb {
    margin-top: 2.5em;
  }

  @include breakpoint(setting('breakpoint.medium')) {
    position: absolute;
    z-index: 1;
  }


  .fa {
    color: color(orange);
    margin-right: 5px;
    position: relative;
    top: 1px;
    font-size: 1.14em;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}