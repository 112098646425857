.connect-bar {
  padding: 3.875rem 0 !important;

  .grid-item {
    margin-bottom: 0 !important;
  }
}

.connect-bar__title {
  color: white;
  font-size: 1.875rem;
  margin-bottom: .75em;
  text-align: center;

  @include breakpoint(setting('breakpoint.medium')) {
    margin-bottom: 0;
    text-align: left;
  }
}

.connect-bar__social-icons {
  text-align: center;

  @include breakpoint(setting('breakpoint.medium')) {
    text-align: right;
  }
}

.connect-bar__icon {
  background-color: rgba(0,0,0,0.2);
  color: white;
  display: inline-block;
  font-size: 1.25em;
  height: 50px;
  position: relative;
  text-align: center;
  transition: all .25s;
  width: 50px;

  &:hover {
    background-color: rgba(0,0,0,0.3);
  }

  .icon {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
