.section-wrapper {
  background-color: transparent;
  margin-bottom: setting('gutterPaddingSmall');
  margin-top: setting('gutterPaddingSmall');
  padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');

  @include breakpoint(setting('breakpoint.medium')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }

  //@include breakpoint(setting('breakpoint.large')) {
  //  padding: setting('gutterPadding') setting('gutterPadding');
  //}

  .global-header & {
    @include breakpoint(setting('breakpoint.large')) {
      padding: setting('gutterPaddingSmall') setting('gutterPaddingMedium')
    }
  }

  &.-white {
    background-color: white;
  }

  &.-gray {
    background-color: color(gray, xlight);
  }

  &.-green {
    background-color: color(green);
  }

  &.-flush-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.-flush-top {
    margin-top: 0;
    padding-top: 0;
  }

  &.-flush-left {
    padding-left: 0;
  }

  &.-flush-right {
    padding-right: 0;
  }

  &.-flush-all {
    margin: 0;
    padding: 0;
  }
}
