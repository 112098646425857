.accordion--nav {
  display: flex;
  justify-content: flex-end;

  label {
    font-weight: normal;
    color: color(gray, base);
  }

  input {
    position: relative;
    visibility: hidden;

    &:before {
      color: color(gray, xdark);
      display: block;
      font-family: $nsccIcons;
      font-size: 1.375em;
      line-height: 0.65;
      position: absolute;
      top: 0;
      right: 8px;
      transition: transform .5s, opacity .3s;

      visibility: visible;
      content: '\e803'; // Down arrow
      transform: rotate(0deg);
      z-index: 20;
    }
  }

  input:checked {
    &:before {
      transform: rotate(180deg);
    }
  }
}

.accordion {
  .accordion__item {
    @include transition(background-color);
    border-bottom: 1px solid color(gray, xdark);

    .accordion__item__details {
      height: 100%;
      max-height: 0;
      margin-left: setting('gutterPadding');
      margin-right: setting('gutterPadding');
      overflow: hidden;
      -webkit-transition: max-height .5s ease-in-out;
      transition: max-height .5s ease-in-out;

      &:after {
        content: '';
        display: block;
        height: 1.250em;
        width: 100%;
      }

      p {
        margin-bottom: 1.75em;
      }

      ul {
        // padding: 0;
        padding-left: 1.85em;

        li {
          // list-style: none;
          color: color(blue);
        }
      }

      p > a {
        @include link(.8, 0);
      }
    }

    &.-expanded {
      .accordion__item__details {
        max-height: 500000px;
      }
    }

    &:not(.-expanded):hover {
      background-color: color(grey, xlight);
    }
  }

  h4 {
    margin-top: 0;
    padding-left: setting('gutterPadding');
    padding-top: 3em;
    padding-bottom: 3em;
    padding-right: 60px;
  }
}

.accordion__item__handle {
  color: color(blue);
  font-size: 1.375rem;
  margin-top: 0;
  position: relative;
  transition: color .3s;

  cursor: pointer;
  padding: 2.5em 0;


  &:hover {
    color: color(blue);
  }

  .accordion__item.-expanded & {
    color: color(blue);
  }

  &:before,
  &:after {
    color: color(gray, xdark);
    display: block;
    font-family: $nsccIcons;
    line-height: 1;
    position: absolute;
    right: setting('gutterPadding');
    top: calc(50% - 12px);
    transition: transform .3s, opacity .3s;
  }

  &:before {
    content: '\e803'; // Down arrow
    transform: rotate(0deg);
    z-index: 20;

    .accordion__item.-expanded & {
      transform: rotate(180deg);
    }
  }
}
