.icon-links {
  padding-top: setting('gutterPadding');
  padding-bottom: setting('gutterPadding');

  a:not(:first-child) {
    &:after{
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: -20px;
      border-left: 1px solid #979797;
    }
  }
  a {
    @include transition(background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    text-align: center;
    position: relative;
    justify-content: space-evenly;

    &:hover {
      background-color: color(grey, xlight);
    }
  }

  img {
    width: 60px;
  }

  h4 {
    font-weight: 400;
    margin: 0;
  }
}