.listing-container {
  display: grid;
  grid-row-gap: setting('gutterPadding');
  grid-auto-rows: 1fr;

    @include breakpoint(setting('breakpoint.small')) {
      grid-row-gap: setting('gutterPaddingLarge');
    }
  }

.listing-item {
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 100%;

  @include breakpoint(setting('breakpoint.medium')) {
    grid-template-columns: 35% auto;
  }

  img {
    width: 100%;
  }
}

.listing-item__image {
  grid-column: 1;
  align-self: start;
}

.listing-item__heading {
  h1 {
    font-family: $openSansCondensed;
    font-size: 1.188rem;
  }
  p {
    margin: 0;
  }
}

.listing-item__subhead p {
  font-size: 0.875rem;
}

.listing-item__content {
  display: flex;
  flex-direction: column;

  @include breakpoint(setting('breakpoint.medium')) {
    grid-column: 2;
  }
  a {
    text-decoration: none;
  }
}

.listing-item__description {
  font-size: 0.875rem;
}

.listing-item__tags {
  margin-top: auto;
  font-size: 0.75em;
  font-weight: 300;
  color: color(blue);

  a {
    color: color(blue);
    text-decoration: underline;
    transition: color .2s;

    &:hover {
      color: lighten(color(blue), 20%);
    }
  }
}

.listing-item__button {
  margin-top: auto;
}