.listing-block {
  display: block;
  min-height: 230px;
  outline: none;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.listing-block__image {
  opacity: .85;
  transition: opacity .5s;
  width: 100%;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  .listing-block:hover & {
    opacity: 1;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  &.-no-image {
    background-color: color(gray, light);
    min-height: 176px;
  }
}

.listing-block__caption {
  color: color(gray);
  display: block;
  font-size: 1em;
  margin-top: .5em;
}
