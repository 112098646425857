.docs-search {
  margin-top: 15px;
}

input.docs-search__box {
  align-self: center;
  background: white;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: color(blue);
  height: 25px;
  padding: .4em 1em;

  @include placeholder {
    color: color(blue);
  }

  &:active,
  &:focus,
  &:active:focus {
    box-shadow: none;
    outline: 0;
  }
}

.docs-search__button {
  background-color: transparent;
  color: color(blue);
  display: none;
  font-size: 1.375em;
  line-height: 1;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  transition: color .3s;
  z-index: 90;

  // States

  .docs-search.-active &,
  &:hover {
    color: lighten(color(blue), 25%);
  }

  &:active,
  &:active:focus,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}