.photo-gallery-ProductNav_Wrapper {
  position: relative;
  padding: 0 11px;
  box-sizing: border-box;
}

.photo-gallery-ProductNav {
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* If JS present, let's hide the default scrollbar */
  .js & {
    /* Make an auto-hiding scroller for the 3 people using a IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Remove the default scrollbar for WebKit implementations */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  /* positioning context for advancers */
  position: relative;
  // Crush the whitespace here
  font-size: 0;
}

.photo-gallery-ProductNav_Contents {
  float: left;
  transition: transform .2s ease-in-out;
  position: relative;
}

.photo-gallery-ProductNav_Contents-no-transition {
  transition: none;
}

.photo-gallery-ProductNav_Link {
  text-decoration: none;
  color: #888;
  // Reset the font size
  font-size: 1.2rem;
  font-family: -apple-system, sans-serif;
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  padding: 0 11px;

  &[aria-selected="true"] {
    color: #111;
  }

  img {
    max-width: initial;
  }
}

.photo-gallery-Advancer {
  /* Reset the button */
  appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  width: 80px;
  margin-bottom: 0;
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }
  /* Now style it as needed */
  position: absolute;
  top: 0;
  bottom: 0;
  /* Set the buttons invisible by default */
  opacity: 0;
  transition: opacity .3s;
}

.photo-gallery-Advancer_Left {
  background-image: linear-gradient(to right, #f5f5f0, transparent, transparent);
  left: 0;
  [data-overflowing="both"] ~ &,
  [data-overflowing="left"] ~ & {
    opacity: 1;
  }
}

.photo-gallery-Advancer_Right {
  background-image: linear-gradient(to left, #f5f5f0, transparent, transparent);
  right: 0;
  [data-overflowing="both"]  ~ &,
  [data-overflowing="right"] ~ & {
    opacity: 1;
  }
}

.photo-gallery-Advancer_Icon {
  font-size: 50px;
  color: color(blue);
  opacity: .7;
}

.photo-gallery-ProductNav_Indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100px;
  background-color: transparent;
  transform-origin: 0 0;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
}

@keyframes lum-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes lum-fadeZoom {
  0% { transform: scale(0.5); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes lum-loader-rotate {
  0% { transform: translate(-50%, -50%) rotate(0); }
  50% { transform: translate(-50%, -50%) rotate(-180deg); }
  100% { transform: translate(-50%, -50%) rotate(-360deg); }
}

@keyframes lum-loader-before {
  0% { transform: scale(1); }
  10% { transform: scale(1.2) translateX(6px); }
  25% { transform: scale(1.3) translateX(8px); }
  40% { transform: scale(1.2) translateX(6px); }
  50% { transform: scale(1); }
  60% { transform: scale(0.8) translateX(6px); }
  75% { transform: scale(0.7) translateX(8px); }
  90% { transform: scale(0.8) translateX(6px); }
  100% { transform: scale(1); }
}

@keyframes lum-loader-after {
  0% { transform: scale(1); }
  10% { transform: scale(1.2) translateX(-6px); }
  25% { transform: scale(1.3) translateX(-8px); }
  40% { transform: scale(1.2) translateX(-6px); }
  50% { transform: scale(1); }
  60% { transform: scale(0.8) translateX(-6px); }
  75% { transform: scale(0.7) translateX(-8px); }
  90% { transform: scale(0.8) translateX(-6px); }
  100% { transform: scale(1); }
}

.lum-lightbox {
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.lum-lightbox-inner {
  top: 2.5%;
  right: 2.5%;
  bottom: 2.5%;
  left: 2.5%;
}

.lum-lightbox-inner img {
  position: relative;
}

.lum-lightbox-inner .lum-lightbox-caption {
  margin: 0 auto;
  color: #fff;
  max-width: 700px;
  text-align: center;
}

.lum-loading .lum-lightbox-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66px;
  height: 20px;
  animation: lum-loader-rotate 1800ms infinite linear;
}

.lum-lightbox-loader:before, .lum-lightbox-loader:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
}

.lum-lightbox-loader:before {
  left: 0;
  animation: lum-loader-before 1800ms infinite linear;
}

.lum-lightbox-loader:after {
  right: 0;
  animation: lum-loader-after 1800ms infinite linear;
  animation-delay: -900ms;
}

.lum-lightbox.lum-opening {
  animation: lum-fade 180ms ease-out;
}

.lum-lightbox.lum-opening .lum-lightbox-inner {
  animation: lum-fadeZoom 180ms ease-out;
}

.lum-lightbox.lum-closing {
  animation: lum-fade 300ms ease-in;
  animation-direction: reverse;
}

.lum-lightbox.lum-closing .lum-lightbox-inner {
  animation: lum-fadeZoom 300ms ease-in;
  animation-direction: reverse;
}

.lum-img {
  transition: opacity 120ms ease-out;
}

.lum-loading .lum-img {
  opacity: 0;
}

.lum-gallery-button {
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100px;
  max-height: 100%;
  width: 60px;
  cursor: pointer;
}

.lum-previous-button {
  left: 12px;
}

.lum-next-button {
  right: 12px;
}

.lum-gallery-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  border-top: 4px solid rgba(255, 255, 255, 0.8);
}

.lum-previous-button:after {
  transform: translateY(-50%) rotate(-45deg);
  border-left: 4px solid rgba(255, 255, 255, 0.8);
  box-shadow: -2px 0 rgba(0, 0, 0, 0.2);
  left: 12%;
  border-radius: 3px 0 0 0;
}

.lum-next-button:after {
  transform: translateY(-50%) rotate(45deg);
  border-right: 4px solid rgba(255, 255, 255, 0.8);
  box-shadow: 2px 0 rgba(0, 0, 0, 0.2);
  right: 12%;
  border-radius: 0 3px 0 0;
}

///* This media query makes screens less than 460px wide display in a "fullscreen"-esque mode. Users can then scroll around inside the lightbox to see the entire image. */
//@media (max-width: 460px) {
//  .lum-lightbox-image-wrapper {
//    display: block;
//    overflow: auto;
//    -webkit-overflow-scrolling: touch;
//  }
//
//  .lum-lightbox-caption {
//    width: 100%;
//    position: absolute;
//    bottom: 0;
//  }
//
//  .lum-lightbox-inner img {
//    max-width: none;
//    max-height: none;
//    display: block;
//  }
//}
