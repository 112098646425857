.label {
  font-size: .8125rem;
  font-weight: 500;
  margin: 1em 0;
  text-transform: uppercase;
  letter-spacing: 5px;

  &.-white {
    color: white;
  }

  &.-gray {
    color: color(gray);
  }

  &.-green {
    color: lighten(color(green), 20%);
  }
}
