.nav-menu {
  background-color: white;
  border-bottom: 7px solid color(orange);
  padding: 25px 20px;
  min-width: 420px;

  @include breakpoint(setting('breakpoint.large')) {
    border-radius: 3px;
    border-top: 1px solid color(grey, light);
    border-right: 1px solid color(grey, light);
    border-left: 1px solid color(grey, light);
    position: absolute;
  }

  .primary-nav & {
    @include breakpoint(setting('breakpoint.large')) {
      margin-top: -1px;
      position: absolute;
      transition: all 0s; // 0s transition so that we can get the transition-delay but without any animation.
      transition-delay: .35s;
      -webkit-box-shadow: 0 2px 7px 0 rgba(0,0,0,0.16);
      -moz-box-shadow: 0 2px 7px 0 rgba(0,0,0,0.16);
      box-shadow: 0 2px 7px 0 rgba(0,0,0,0.16);

      &:not(.edge):before {
        content: '';
        left: 19px;
        width: 0;
        height: 0;
        border-left: 21px solid transparent;
        border-right: 21px solid transparent;
        border-bottom: 21px solid #DDDDDD;
        top: -21px;
        position: absolute;
      }

      &:not(.edge):after {
        content: '';
        left: 20px;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid white;
        top: -20px;
        position: absolute;
      }

      &.edge {
        right: 0;

        &:before {
          content: '';
          right: 19px;
          width: 0;
          height: 0;
          border-left: 21px solid transparent;
          border-right: 21px solid transparent;
          border-bottom: 21px solid #DDDDDD;
          top: -21px;
          position: absolute;
        }

        &:after {
          content: '';
          right: 20px;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 20px solid white;
          top: -20px;
          position: absolute;
        }
      }
    }
  }

  .primary-nav > li:hover & {
    @include breakpoint(setting('breakpoint.large')) {
      &:not(.-docs-tag) {
        display: block;
      }
    }
  }

  .primary-nav > li:hover {
    &.-docs {
      @include breakpoint(setting('breakpoint.large')) {
        display: block;
        position: relative;
        border: none;
      }
    }
  }


  .container > nav {
    @include clearfix;
    color: white;
    -webkit-columns: 220px 2;
    -moz-columns: 220px 2;
    columns: 220px 2;
    width: 100%;

    @include breakpoint(setting('breakpoint.large')) {
      border-left: 1px solid color(gray, dark);
      float: right;
      margin: 40px 0;
      padding: 10px 55px;
      width: 52%;

      ul {
        float: left;
        width: 50%;
      }
    }

    div{
      padding-bottom: 1.5em;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
    }
  }

  h3 {
    color: white;
  }

  .nav-menu__section-overview {
    display: none;

    @include breakpoint(setting('breakpoint.large')) {
      @include clearfix;
      color: white;
      display: block;
      float: left;
      min-height: 325px;
      padding: 50px 5% 0 22%;
      position: absolute;
      width: 48%;
    }

    h3 {
      font-size: 2.125em;
      line-height: 1;
      margin-top: 11px;
    }

    p {
      color: color(gray, light);
      font-size: .875em;
      line-height: 1.375;
      margin-bottom: 0;
      margin-top: 14px;

    }

    .photo {
      background-color: color(gray);
      left: 0;
      min-height: 165px;
      position: absolute;
      width: 38%;

      @include breakpoint(setting('breakpoint.xlarge')) {
        min-height: 225px;
      }
    }

    a {
      //background: url(../images/chevron-right-green.png) no-repeat right 5px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .5px;
      padding-right: 20px;
      text-decoration: none;
    }
  }
}