.e2ma_signup_form {
  padding: 0 !important;

  @extend .global-footer;
}

.e2ma_signup_form_button {
  @extend  .button;
  @extend .-primary;
  @extend .-small;
}

.e2ma_signup_form_row {
  @extend .form-control-group;
}

.e2ma_signup_form_element > input {
  @extend .form-control;
}

.e2ma_signup_form_label {
  @extend label;
}