.loader {
  margin: 0px auto;
  position: relative;
  width: 80px;

  &:before {
    content:'';
    display: block;
    padding-top: 100%;
  }

  &.hidden {
    display: none;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  margin: auto;
  position: absolute;
  transform-origin: center center;
  top: 0; bottom: 0; left: 0; right: 0;
  width: 100%;
}


.path {
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}
@keyframes dash{
  0%{
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color{
  100%, 0%{
    stroke: color(green);
  }
  40%{
    stroke: color(gray, light);
  }
  66%{
    stroke: lighten(color(green), 25%);
  }
  80%, 90%{
    stroke: color(gray, dark);
  }
}
