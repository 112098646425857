$media: (
  floats: left right,
  wide: (
    breakpoint: em(1084px + setting('gutterPadding') + setting('gutterPadding')),
    width: 1084px
  )
);

.media {
  @include clearfix;
  max-width: 868px;
  margin: 40px auto 50px;
  position: relative;
  text-align: center;
  width: 100%;

  @include breakpoint(setting('breakpoint.medium')) {
    width: auto;
  }

  @include breakpoint(setting('wide.breakpoint', $media)) {
    margin: 50px auto;
  }

  .embed {
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
    
    .photo {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  // Modifiers

  &.-wide {
    @include breakpoint(setting('wide.breakpoint', $media)) {
      width: setting('wide.width', $media);
    }
  }

  @each $direction in setting('floats', $media) {
    $classname: -#{$direction};
    $opposite: if($direction == 'left', right, left);

    &.#{$classname} {
      @include breakpoint(480px) {
        float: $direction;
        margin-#{$opposite}: setting('gutterPadding');
      }

      @include breakpoint(width 480px 599px) {
        width: 50%;
      }

      @include breakpoint(800px) {
        margin-#{$direction}: -#{setting('gutterPadding') * 2};
      }
    }

    .video-embed, img {
      width: 100%;
    }
  }

  &.-right {
    @include breakpoint(800px) {
      margin-right: -#{setting('gutterPadding')};
    }

    @include breakpoint(setting('wide.breakpoint', $media)) {
      margin-top: 45px;
    }
  }

  &.-width-75 {
    width: 75%;
  }

  &.-width-66 {
    width: 66%;
  }

  &.-width-50 {
    width: 50%;
  }

  &.-width-33 {
    width: 33%;
  }

  &.-width-25 {
    width: 25%;
  }


  // Components

  .media__content {
    margin-top: 30px;

    @include breakpoint(400px) {
      margin: 28px auto 0;
      width: 75%;
    }

    @include breakpoint(setting('wide.breakpoint', $media)) {
      margin: 48px auto 0;
      width: percent(604px, 868px);
    }
  }
}