// Credit: Nicolas Gallagher and SUIT CSS.

.video-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .video-embed-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.video-embed-21by9 {
  &::before {
    padding-top: percentage(9 / 21);
  }
}

.video-embed-16by9 {
  &::before {
    padding-top: percentage(9 / 16);
  }
}

.video-embed-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}

.video-embed-1by1 {
  &::before {
    padding-top: percentage(1 / 1);
  }
}
