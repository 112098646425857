$hero: (
  backgroundColor: color(gray, xdark),
  color: #fff,
  height: (
    standard: (
      small: 360px,
      medium: 435px,
      large: 490px
    ),
    oversized: (
      small: '',
      medium: '',
      large: 770px
    ),
    small: (
      small: 250px,
      medium: 320px,
      large: 390px
    )
  )
);

.hero {
  @include clearfix;
  background-color: setting('backgroundColor', $hero);
  position: relative;

  &.-with-image {
    background-position: center center;
    background-size: cover;
    color: #fff;
    height: setting('height.standard.small', $hero);
    overflow: hidden;

    @include breakpoint(setting('breakpoint.medium')) {
      height: setting('height.standard.medium', $hero);
    }

    @include breakpoint(setting('breakpoint.large')) {
      height: setting('height.standard.large', $hero);
    }

    &:after {
      background-color: rgba(0,0,0,0.3);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }

    &.-top {
      background-position: top;
    }
  }

  .container {
    align-items: center;
    height: 100%;
    z-index: 20;
    max-width: 85vw;
    margin-left: auto;
    margin-right: auto;
  }

  &.-oversized {
    height: setting('height.oversized.large', $hero) !important;
  }

  &.-small {
    height: setting('height.small.small', $hero);

    .Carousel {
      height: setting('height.small.small', $hero);      
    }

    @include breakpoint(setting('breakpoint.medium')) {
      height: setting('height.small.medium', $hero);

      .Carousel {
        height: setting('height.small.medium', $hero);      
      }
    }

    @include breakpoint(setting('breakpoint.large')) {
      height: setting('height.small.large', $hero);

      .Carousel {
        height: setting('height.small.large', $hero);      
      }
    }
  }

  video {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
