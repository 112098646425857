.global-footer {
  background-color: color(blue);
  color: color(gray, xlight);
  font-size: .813rem;
  font-weight: 300;
  padding: 60px 0 25px;
  text-align: center;

  @include breakpoint(setting('breakpoint.small')) {
    text-align: left;
  }

  li {
    margin-bottom: 0;
  }

  p {
    color: color(gray, xlight);
    margin: 0;
    font-size: .813rem;
    font-weight: 300;

    a {
      color: color(gray, light);
      text-decoration: none;
      transition: color .25s;

      &:before {
        background-color: color(gray);
      }

      &:hover {
        color: color(gray);

        &:before {
          background-color: color(gray, light);
        }
      }
    }
  }

  .form-control {
    height: 31px;
    line-height: 1.33;
    padding: 6px 8px;
  }

  .form-control-group {
    margin-bottom: 0.625em;
    margin-top: 0.625em;
  }
}

.global-footer__heading {
  color: color(gray, xlight);
  font-size: .813rem;
  font-family: $openSans;
  font-weight: 700;
  text-transform: none;
}

.global-footer__social {
  padding-bottom: 20px;

  .grid.-col3 {
    grid-template-columns: 50px 50px 50px;

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  a {
    color: color(gray, light);
    text-decoration: none;
    transition: color .25s;

    &:hover {
      color: color(gray);
    }
  }

  i {
    font-size: 32px;
  }

  p {
    text-align: right;
    color: color(gray);
    font-size: .6875rem;
    line-height: 1.3;
  }

  #donate {
    padding-top: 50px;
  }
}

.global-footer__social-container {
  display: flex;
  justify-content: center;
}

.global-footer__legal {
  text-align: center;
  margin-top: 60px;
}