ul, ol {
  margin-left: 0.15em;
}

li {
  color: #03123F;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 0;
  }

  .article & {
    a {
      @include link();
    }
  }
}
