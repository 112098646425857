.global-footer__contact-info {
  line-height: 1.67em;

  a {
    color: white;
    font-weight: 300;
    text-decoration: none;
    transition: color .25s;

    &:hover {
      color: color(gray, light);
    }
  }
}
