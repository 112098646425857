.embed {
  background-color: #000;
  cursor: pointer;

  .photo {
    opacity: .8;
    -webkit-transition: opacity;
    transition: opacity;
    width: 100%;

    &:hover {
      opacity: .4;
    }
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.-modal-overlay[data-loaded=false]::before {
    background-color: transparent;
    background-image: -webkit-linear-gradient(180deg, transparent, rgba(0, 0, 0, .6));
    background-image: linear-gradient(-90deg, transparent, rgba(0, 0, 0, .6));
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transition: all;
    transition: all;
    width: 100%;
    z-index: 2;
  }
}

.embed[data-loaded=false]::before {
  background: url('../../src/images/play.svg') no-repeat;
  content: '';
  height: 70px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 70px;
  z-index: 1;
}

.embed[data-loaded=false]:hover {
  -webkit-transition: opacity;
  transition: opacity;

  .photo {
    opacity: .4;
  }

  &::before {
    opacity: .8;
  }
}

.embed[data-loaded=true] {
  height: 0;
  padding-bottom: 56.25%;

  .network-modal-content {
    display: none;
  }
}
