.sidebar-container {
  background-color: color(green);
  padding: setting('gutterPaddingSmall');
  margin-top: setting('gutterPadding');

  h4 {
    font-family: $openSansCondensed;
    font-size: 1.250em;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    padding: setting('gutterPaddingSmall') 0;
  }
}

.sidebar-nav {
  background-color: white;
  display: block;
  width: 100%;

  ul {
    font-size: .875rem;
    padding: 12px 20px;
  }
}

.sidebar-nav__title {
  background-color: color(blue);
  color: white;
  font-weight: 600;
  margin: 0;
  padding: 7px 15px;
  text-transform: uppercase;
}

.sidebar-nav__item {
  font-size: .9375em;
  font-weight: 400;
  list-style: none;

  a {
    color: color(blue);
    left: 0;
    position: relative;
    transition: color .2s;

    &:hover {
      color: lighten(color(blue), 20%);
    }
  }

  &.-active a {
    font-weight: 600;
  }
}
