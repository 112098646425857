// Fonts
.baskerville {
  font-family: $baskerville;
}

.monospace {
  font-family: $monospace;
}

.visually-hidden {
  display: none;
}
