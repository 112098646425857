.article {
  padding: setting('gutterPaddingSmall');

  @include breakpoint(setting('breakpoint.medium')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }
}

.article__title {
  color: color(blue);
  font-size: 1.375rem;
  text-transform: uppercase;
}

.article__title-alt {
  font-size: 3rem;
  margin-bottom: 1em;
}

.social-post-container .fab {
  font-size: 2em;
  margin-right: .5em;
}