.tab-panel {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .25s;

  &.is-active {
    height: auto;
    opacity: 1;
  }
}
