$grids: (
  col2: (
    layout: (
      gutters: 23/500,
      gutter-position: after
    ),
    breakpoints: (
      xsmall: 1,
      small: 1,
      medium: 2,
      large: 2
    ),
    spans: (
      1 _5: (
        xsmall: full,
        small: full,
        medium: 1.5,
        large: 1.5
      ),
      2: (
        xsmall: full,
        small: full,
        medium: full,
        large: full
      )
    )
  ),
  col3: (
    layout: (
      gutters: 7/100,
      gutter-position: after
    ),
    breakpoints: (
      xsmall: 1,
      small: 1,
      medium: 3,
      large: 3
    ),
    spans: (
      2: (
        xsmall: full,
        small: full,
        medium: 1.5,
        large: 1.5
      )
    )
  ),
  col4: (
    layout: (
      gutters: 19/200,
      gutter-position: after
    ),
    breakpoints: (
      xsmall: 1,
      smallOnly: 1,
      mediumOnly: 3,
      large: 4
    ),
    spans: (
      1 _5: (
        xsmall: full,
        small: full,
        medium: 1.5,
        large: 1.5
      ),
      2: (
        xsmall: full,
        small: full,
        medium: 1,
        large: 2
      ),
      2 _5: (
        xsmall: full,
        small: full,
        medium: 2.5,
        large: 2.5
      )
    )
  ),
  col6: (
    layout: (
      gutters: 15/100,
      gutter-position: after
    ),
    breakpoints: (
      xsmall: 1,
      smallOnly: 2,
      mediumOnly: 4,
      large: 6
    ),
    spans: (
      5: (
        xsmall: full,
        smallOnly: full,
        mediumOnly: 3,
        large: 5
      ),
      4 _5: (
        xsmall: full,
        smallOnly: 2,
        mediumOnly: 2.5,
        large: 4.5
      ),
      4: (
        xsmall: full,
        smallOnly: 2,
        mediumOnly: 2.5,
        large: 4
      ),
      3: (
        xsmall: full,
        smallOnly: 1,
        mediumOnly: 2,
        large: 3
      ),
      2: (
        xsmall: full,
        smallOnly: 2,
        mediumOnly: 1.5,
        large: 2
      ),
      1 _5: (
        xsmall: full,
        smallOnly: 2,
        mediumOnly: 1.5,
        large: 1.5
      )
    )
  )
);

.grid {
  display: grid;
  position: relative;
  z-index: 1;

  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-row-gap: 15px;
  grid-column-gap: 40px;

  &.-small {
    grid-row-gap: 20px;
    grid-column-gap: 15px;
  }

  &.-footer {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: auto auto auto 130px;
    }
  }

  &.-col4 {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &.-col5 {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.-col6 {
    grid-template-columns: 1fr 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.-col7 {
    grid-template-columns: 1fr 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.-col8 {
    grid-template-columns: 1fr 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.-featured-entry-grid {
    grid-template-rows: auto auto;
    grid-column-gap: setting('gutterPadding');

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
    }
  }

  &.-listing-grid {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-column-gap: 15px;
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-column-gap: 30px;
      grid-template-columns: 18.4% auto 25% 25%;
    }

    @include breakpoint(setting('breakpoint.xlarge')) {
      grid-column-gap: 40px;
      grid-template-columns: 18.4% auto 27.5% 23%;
    }
  }

  &.-col3 {
    grid-template-columns: 1fr 1fr;

    @include breakpoint(setting('breakpoint.small')) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &.-col3-2-1 {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.small')) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &.-col3-1 {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(setting('breakpoint.large')) {
      grid-column-gap: calc(40px * 1.5);
    }
  }

  &.-col2 {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.-detail {
    grid-template-columns: 1fr;

    @include breakpoint(setting('breakpoint.medium')) {
      grid-template-columns: 1fr 250px;
    }
  }
}

[class*="-fixcols"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @include breakpoint(setting('breakpoint.medium')) {
    flex-direction: row;
    align-items: normal;
  }

  > div {
    max-width: 280px;

    @include breakpoint(setting('breakpoint.large')) {
      max-width: none;
    }
  }
}

.-fixcols2 {
  > div {
    @include breakpoint(setting('breakpoint.medium')) {
      flex-basis: 50%;
    }
  }
}

.-fixcols3 {
  > div {
    @include breakpoint(setting('breakpoint.medium')) {
      flex-basis: 50%;
    }

    @include breakpoint(setting('breakpoint.xxlarge')) {
      flex-basis: 33%;
    }
  }
}

.-fixcols4 {
  > div {
    @include breakpoint(setting('breakpoint.medium')) {
      flex-basis: 50%;
    }

    @include breakpoint(setting('breakpoint.xlarge')) {
      flex-basis: 33%;
    }

    @include breakpoint(setting('breakpoint.xxlarge')) {
      flex-basis: 25%;
    }
  }
}