.featured-event__container {
    padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');
    margin: setting('gutterPaddingSmall') 0;

    @include breakpoint(setting('breakpoint.large')) {
      padding: setting('gutterPaddingSmall') setting('gutterPadding');
    }

  h2 {
    text-transform: uppercase;
  }

  > p {
    font-size: 1.188em;
    margin-bottom: setting('gutterPaddingSmall');
    margin-top: 0;
  }
}

.featured-event {
  overflow: hidden;
  position: relative;
  width: 100%;
  @include clearfix;
}

//.featured-event__grid {
//  @extend .-featured-event-grid;
//  height: 100%;
//  align-items: center;
//  padding: setting('gutterPadding') 0;
//
//  @include breakpoint(setting('breakpoint.large')) {
//    padding: setting('gutterPadding') 90px;
//  }
//}

.featured-event__content {
  position: relative;
  height: 100%;
  z-index: 20;
  text-align: center;

  @include breakpoint(setting('breakpoint.medium')) {
    text-align: right;
  }
}

.featured-event__content__headline {
  font-family: $baskerville;
  font-style: italic;
  font-size: 1.188em;
  line-height: 1;
}

.featured-event__image {
  width: 100%;
}

.featured-event__cta__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @include breakpoint(setting('breakpoint.medium')) {
    flex-direction: row;
  }

    .button {
    @include breakpoint(setting('breakpoint.medium')) {
      align-self: flex-end;
    }
  }
}

.featured-event__meta {
  font-family: $openSans;
  font-weight: 600;
  font-size: 1.063em;
  position: relative;

  p {
    margin: 0;
    font-weight: 600;
  }
}