.upcoming-events {
  background-color: color(grey, xlight);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');
  margin: setting('gutterPaddingSmall') 0;

  @include breakpoint(setting('breakpoint.large')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }
}

.upcoming-events__controls {
  align-self: center;

  i {
    @include transition(color);

    color: color(blue);
    font-size: 40px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      color: lighten(color(gray), 10%);
    }
  }
}
