.nav-link {
  @include transition(color);
  display: block;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  position: relative;
  text-align: left;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;

  @include breakpoint(setting('breakpoint.large')) {
    text-align: center;
  }

  // States

  &:active,
  &:active:focus {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  // Types

  &.-primary {
    color: color(blue);
    font-size: 1rem;
    font-weight: 600;
    padding: setting('gutter') setting('gutter') 15px;
    z-index: 3;

    @include breakpoint(setting('breakpoint.large')) {
      height: 100%;
      padding: 41px 15px;
      font-size: .9rem;
    }

    .primary-nav.expanded & {
      color: color(blue);

      &:hover {
        color: lighten(color(blue), 5%);
      }
    }

    .primary-nav > li:hover & {
      color: lighten(color(blue), 5%);
    }

    .primary-nav > .-current & {
      @include breakpoint(setting('breakpoint.large')) {
        &:before {
          border-bottom: 4px solid color(orange);
          content: '';
          position: absolute;
          top: calc(41px + 16px + 5px);
          width: calc(100% - 30px);
        }
      }
    }

    i {
      display: none;

      @include breakpoint(setting('breakpoint.large')) {
        display: inline;
      }
    }

    .primary-nav > li:hover &.-active,
    .primary-nav > li:hover &:hover,
    .primary-nav > li:hover &:active,
    .primary-nav > li:hover &:focus {
      color: lighten(color(blue), 25%);
    }
  }

  &.-dropdown-link {
    color: color(blue);
    font-size: 15px;
    font-weight: 400;
    padding: .4em 1em;

    @include breakpoint(setting('breakpoint.large')) {
      font-size: 13px;
      padding-left: 13px;
      padding-right: 13px;
      padding-top: 10px;
    }

    .primary-nav & {
      @include breakpoint(setting('breakpoint.large')) {
        display: block;
        font-size: 15px;
        margin: 0 5% 14px 0;
        min-width: 140px;
        padding: 0;
        text-align: left;
      }
    }

    &.-active,
    &:hover,
    &:active,
    &:focus {
      @include breakpoint(setting('breakpoint.large')) {
        color: lighten(color(blue), 25%);
      }
    }
  }

  &.-secondary {
    color: color(grey, light);
    font-size: 13px;
    font-weight: 600;
    padding: 0 1em;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      @include breakpoint(setting('breakpoint.large')) {
        color: lighten(color(grey, light), 25%);
      }
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}
