.global-footer__partners {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: inline-block;
    margin: 0 .75em;
  }

  img,
  svg {
    max-height: 41px;
    width: auto;
  }

  svg path,
  svg rect,
  svg polygon {
    fill: color(gray);
  }

  #tncc-logo {
    height: 32px;
  }

  #tnecampus-logo {
    height: 28px;
  }

  #pathway-logo {
    height: 20px;
  }

  #workforce-logo {
    height: 30px;
  }
}
