.four-o-four-pg {

    .global-search {
        position: relative;
        margin: auto;
        display: grid;
        width: 90%;
        grid-template-rows: 100%;
        grid-template-columns: 1fr auto;

        @include breakpoint(setting('breakpoint.small')) {
            width: 70%;
        }
    }

    .button.-primary {
        grid-row: 1;
        grid-column: 2;
        margin-bottom: 0;
    }

    input#global-search {
        font-size: 1em;
        grid-column: 1;
        grid-row: 1;
    }
}