$breakpoint-alpha: 900px; // adjust to your needs

table {
  margin: 1em 0;
  max-width: 100%;
  width: 100%;

  tr {
    border-bottom: 1px solid color(gray, light);
    max-width: 100%;

  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
    max-width: 100%;
  }

  td {
    display: block;
    padding: 0;
    max-width: 100%;
    vertical-align: top;


    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": ";
      color: color(gray, xdark);
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 30px;
      display: inline-block;
      padding-right: 15px;

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  @media (min-width: $breakpoint-alpha) {
    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid color(gray, xlight);
    }
  }

  th, td {
    text-align: left;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

  }

  //&.tablesort {
  //  th {
  //    background-image: url("../images/chevron-up-grey.svg"), url("../images/chevron-down-grey.svg");
  //    background-repeat: no-repeat, no-repeat;
  //    background-position: right 18px top 26px,right 18px bottom 26px;
  //    background-size: 12px 8px, 12px 8px;
  //    cursor: pointer;
  //    color: color(gray, base);
  //    font-size: 11px;
  //    font-weight: 400;
  //    height: 70px;
  //    letter-spacing: 3px;
  //    padding: 1em 40px .68em .75em;
  //    text-transform: uppercase;
  //  }
  //}

}

