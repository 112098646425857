// Usage
// <a class="button -primary" role="button" href="#" role="button">Link</a>
// <button class="button -primary" type="submit">Button</button>
// <input class="button -primary" type="button" value="Input">
// <input class="button -primary" type="submit" value="Submit">

// Settings
$button: (
  backgroundColor: (
    default: color(orange),
    hover: lighten(color(orange), 10%)
  ),
  color: #fff,
  fontSize: .938rem,
  padding: .938rem 1.563rem,
  primary: (
    backgroundColor: (
      default: color(orange),
      hover: lighten(color(orange), 10%)
    )
  ),
  secondary: (
    borderColor: (
      default: color(gray, xlight),
      hover: color(orange)
    ),
    color: (
      default: color(gray, dark),
      hover: color(orange)
    )
  ),
  large: (
    fontSize: .938em,
    padding: .938em 1.563em
  ),
  medium: (
    fontSize: .938em,
    padding: .938em 1.563em
  ),
  small: (
    fontSize: .938em,
    padding: .563em 2.125em
  ),
);

// Definitions
.button,
a.button,
p > a.button {
  background-color: setting('backgroundColor.default', $button);
  background-image: none;
  border: 0;
  border-radius: 2px;
  color: setting('color', $button);
  cursor: pointer;
  display: inline-block;
  font-size: setting('fontSize', $button);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  min-width: 180px;
  padding: setting('padding', $button);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all .3s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  // States

  &:hover,
  &:active,
  &:focus {
    color: setting('color', $button);
    background-color: setting('backgroundColor.hover', $button);
  }

  &:active,
  &:active:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted rgba(#000, .4);
    text-decoration: underline;
  }

  &[disabled],
  &.is-disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: .65;
    pointer-events: none;
  }

  &:before {
    content: none;
  }

  // Types

  &.-primary {
    background-color: setting('primary.backgroundColor.default', $button);
    transition: background-color .25s ease-out;

    &:hover,
    &:active,
    &:focus {
      background-color: setting('primary.backgroundColor.hover', $button);
    }
  }

  &.-secondary {
    background-color: color(purple);

    &:hover {
      background-color: lighten(color(purple), 10%);
    }
    //background-color: transparent;
    //border: 2px solid setting('secondary.borderColor.default', $button);
    //color: setting('secondary.color.default', $button);
    //position: relative;
    //transition: 0.5s;
    //
    //&:after,
    //&:before {
    //  background-color: transparent;
    //  box-sizing: border-box;
    //  content: '';
    //  height: 100%;
    //  left: 0;
    //  position: absolute;
    //  top: 0;
    //  -webkit-transform: scale(0);
    //  -moz-transform: scale(0);
    //  -ms-transform: scale(0);
    //  -o-transform: scale(0);
    //  transform: scale(0);
    //  transition: 0.3s ease-in;
    //  width: 100%;
    //  z-index: 3;
    //}
    //
    //&:before {
    //  border-bottom: 2px solid setting('secondary.borderColor.hover', $button);
    //  border-left: 2px solid setting('secondary.borderColor.hover', $button);
    //  -webkit-transform-origin: 0% 100%;
    //  -moz-transform-origin: 0% 100%;
    //  -ms-transform-origin: 0% 100%;
    //  -o-transform-origin: 0% 100%;
    //  transform-origin: 0% 100%;
    //}
    //
    //&:after {
    //  border-top: 2px solid setting('secondary.borderColor.hover', $button);
    //  border-right: 2px solid setting('secondary.borderColor.hover', $button);
    //  -webkit-transform-origin: 100% 0%;
    //  -moz-transform-origin: 100% 0%;
    //  -ms-transform-origin: 100% 0%;
    //  -o-transform-origin: 100% 0%;
    //  transform-origin: 100% 0%;
    //}
    //
    //&:hover,
    //&:active,
    //&:focus {
    //  border: 2px solid rgba(0, 160, 80, 0);
    //  background-color: transparent;
    //  color: setting('secondary.color.hover', $button);
    //}
    //
    //&:hover:after,
    //&:hover:before {
    //  -webkit-transform: scale(1);
    //  -moz-transform: scale(1);
    //  -ms-transform: scale(1);
    //  -o-transform: scale(1);
    //  transform: scale(1);
    //}
    //
    //&.-white {
    //  border-color: white;
    //  color: white;
    //  transition: all .5s;
    //
    //  &:before,
    //  &:after {
    //    content: none;
    //  }
    //
    //  &:hover {
    //    border-color: transparent;
    //    background-color: rgba(255, 255, 255, 0.6);
    //  }
    //}
  }

  &.-tertiary {
    background-color: color(blue);

    &:hover {
      background-color: lighten(color(blue), 10%);
    }

    &:focus {
      text-decoration: none;
    }
  }

  // Sizes

  &.-large {
    font-size: setting('large.fontSize', $button);
    padding: setting('large.padding', $button);
  }

  &.-medium {
    font-size: setting('medium.fontSize', $button);
    padding: setting('medium.padding', $button);
  }

  &.-small {
    font-size: setting('small.fontSize', $button);
    min-width: auto;
    padding: setting('small.padding', $button);
  }

  // Styles

  &.-block {
    display: block;
    padding: 2em !important;
    width: 100%;
  }

  &.-center {
    justify-self: center;
  }

  &.-full {
    justify-self: stretch;
  }

  &.-left {
    justify-self: left;
  }

  &.-right {
    justify-self: right;
  }
}

.button-container {
  display: grid;
  width: 100%;
}

.button-container + .button-container {
  margin-top: 1em;
}