.sidebar-callout {
  background-color: color(purple);
  padding: setting('gutterPaddingSmall');
  margin: setting('gutterPaddingSmall') 0;
  text-align: center;

  a {
    font-style: normal;
    color: white;
  }

  .sidebar-callout__heading, img {
    color: white;
    font-family: $openSansCondensed;
    font-size: 1.375em;
    margin-bottom: setting('gutterPaddingSmall');
    padding: 0;
  }
}
