$quote: (
  breakpoint: (
    max: em(480px - 1px),
    min: em(480px),
    offset: em(1084px + setting('gutterPadding') + setting('gutterPadding') + 200px)
  ),
  floats: left right,
  maxWidth: 250px,
  offset: -100px
);

.quote {
  @include breakpoint('max-width' setting('breakpoint.max', $quote)) {
    margin-left: 0;
    margin-right: 0;
  }

  @each $direction in setting('floats', $quote) {
    $classname: -#{$direction};

    &.#{$classname} {
      @include breakpoint(setting('breakpoint.min', $quote)) {
        float: $direction;
        margin-#{$direction}: 0;
        margin-top: -1em;
        max-width: setting('maxWidth', $quote);
      }

      @include breakpoint(setting('breakpoint.offset', $quote)) {
        margin-#{$direction}: setting('offset', $quote);
      }
    }
  }
}
