.tablist {
  border-top: 1px solid color(gray, xlight);
  border-left: none;
  margin-top: 0;
  padding: 4em 7%;
  position: relative;

  @include breakpoint(setting('breakpoint.medium')) {
    border-top: none;
    border-left: 1px solid color(gray, xlight);
    margin-top: 4.5em;
    padding: .5em 0 4em 20%;
  }

  ul {
    margin-bottom: 1.7em;
  }
}

.tablist__tab {
  font-size: .875rem;
  list-style: none;
  margin-bottom: 1.7em;

  a {
    @include link(.8, 0);
    color: color(gray);
    transition: color .25s;
  }

  &.is-selected {
    a {
      color: color(gray, dark);
      cursor: text;
      font-weight: 700;

      &:hover {
        color: color(gray, dark);
        text-decoration: none;

        &:before {
          opacity: 0;
        }
      }
    }
  }
}
