$callout: (
  sizes: (
  )
);

.callout {
  @extend .grid;
  @extend .-col2;
  @include transition(background-color);
  color: color(blue);
  position: relative;
  text-decoration: none;
  padding: setting('gutterPaddingSmall') setting('gutterPaddingSmall');
  margin-bottom: setting('gutterPaddingSmall');

  @include breakpoint(setting('breakpoint.large')) {
    padding: setting('gutterPaddingSmall') setting('gutterPadding');
  }
}

a.callout {
  background-color: color(grey, xlight);

  &:hover {
    background-color: darken(color(grey, xlight), 5%);
  }
}

.callout__image {
  display: block;
  height: auto;
  float: right;
  width: 100%;

  @include breakpoint(setting('breakpoint.medium')){
    width: auto;
  }
}