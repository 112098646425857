.grid-filters {
  display: block;

  @include breakpoint(setting('breakpoint.medium')) {
    float: right;
    width: 70%;
  }

  .grid-item {
    margin-bottom: 0;
  }
}

.grid-filters__group {
  @include breakpoint(setting('breakpoint.large')) {
    border-left: 1px solid color(gray, xlight);
    padding-left: gutter(9);
  }

  &:first-of-type {
    border-left: none;
    padding-left: 0;
  }
}
