.paginator { 
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 60px;

    a, .current, .first-page, .last-page {
        padding: 10px;
    }
    a {
        border-top: 0.4px solid #DDDDDD;
        border-right: 0.2px solid #DDDDDD;
        border-bottom: 0.4px solid #DDDDDD;
        max-width: 44px;
        min-width: 44px;
        text-decoration: none;
        background-color: #F5F5F0;
        color: #03123F;
    }
    .current {
        border-top: 0.4px solid #DDDDDD;
        border-right: 0.2px solid #DDDDDD;
        border-bottom: 0.4px solid #DDDDDD;
        background-color: #DDDDDD;
        color: #7D7D7D;
        max-width: 44px;
        min-width: 44px;
    }
    .first-page {
        border: 0.6px solid #DDDDDD;
        border-radius: 8%;
        background-color: #F5F5F0;
        color: #03123F;
        margin-right: 10px;
        min-width: 65px;
    }
    .last-page {
        border: 0.6px solid #DDDDDD;
        border-radius: 8%;
        background-color: #F5F5F0;
        color: #03123F;
        margin-left: 10px;
        min-width: 65px;
    }
    .previous-page {
        border: 0.6px solid #DDDDDD;
        border-top-left-radius: 8%;
        border-bottom-left-radius: 8%;
    }
    .next-page {
        border-top: 0.6px solid #DDDDDD;
        border-right: 0.6px solid #DDDDDD;
        border-bottom: 0.6px solid #DDDDDD;
        border-top-right-radius: 8%;
        border-bottom-right-radius: 8%;
    }
}

.paginator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}