.global-search {
  display: flex;
  position: absolute;
  right: 0;
  top: 1em;
  width: 100%;
  @include clearfix;

  @include breakpoint(setting('breakpoint.large')) {
    height: 100%;
    top: auto;
    width: auto;
  }
}

input.global-search__box {
  align-self: center;
  background: color(blue);
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: color(grey, xlight);
  height: 25px;
  padding: .4em 1em;
  width: 100%;
  -webkit-appearance: none;

  @include placeholder {
    color: color(grey, light);
  }

  @include breakpoint(setting('breakpoint.large')) {
    background: transparent;
    border-bottom: 2px solid lighten(color(blue), 25%);
    margin: 0 0.5em 0 0;
    padding: 0 1em 0 0;
    color: color(gray, xdark);

    @include placeholder {
      color: color(gray, dark);
    }
  }

  &:active,
  &:focus,
  &:active:focus {
    box-shadow: none;
    outline: 0;
  }

  @include breakpoint(setting('breakpoint.large')) {
    margin: 0;
    padding: 0;
    transition: width .3s;
    width: 0;

    .global-search.-active & {
      width: 500px;
      margin: 0 0.5em 0 0;
    }
  }
}

.global-search__button {
  background-color: transparent;
  color: color(blue);
  display: none;
  font-size: 1.375em;
  line-height: 1;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  transition: color .3s;
  z-index: 90;

  @include breakpoint(setting('breakpoint.large')) {
    display: block;
    padding: 36px 12px;
  }

  @include breakpoint(setting('breakpoint.large')) {
      padding-right: 0;
    }

  // States

  .global-search.-active &,
  &:hover {
    color: lighten(color(blue), 25%);
  }

  &:active,
  &:active:focus,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}
