@mixin columns($columns, $layout, $breakpoints: (), $spans: ()) {
  &.-col#{$columns} {
    //@include layout($layout) {
    //  @each $breakpoint, $cols in $breakpoints {
    //    @include susy-breakpoint(setting('breakpoint.' + $breakpoint), $cols) {
    //      > .grid-item {
    //        @include span(1);
    //        margin-bottom: gutter($columns);
    //
    //        @each $span, $spanMap in $spans {
    //          &.-span#{$span} {
    //            @include span(setting($breakpoint, $spanMap));
    //          }
    //        }
    //
    //        @if $cols > 1 {
    //          &:first-child {
    //            padding-left: 0;
    //          }
    //
    //          &:nth-child(#{$cols}n),
    //          &:last-child {
    //            margin-right: 0;
    //            padding-right: 0;
    //          }
    //        }
    //
    //        @content;
    //      }
    //    }
    //  }
    //}
  }
}

@mixin link($initialScale: .9, $initialOpacity: .3) {
  color: color(purple);
  position: relative;
  text-decoration: none;

  &:before {
    position: absolute;
    margin: 0 auto;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: color(purple);
    content: '';
    opacity: $initialOpacity;
    transform: scaleX($initialScale);
    transition: all .3s;
  }

  &:hover:before {
    opacity: 1;
    transform: scaleX(1);
  }
}
