$calloutBlock: (
  sizes: (
    small: (
      fontSize: .938em,
      lineHeight: 1.1,
      height: 200px
    ),
    medium: (
      fontSize: 1.75em,
      lineHeight: 1.2,
      height: 250px
    ),
    large: (
      border: 25px solid white,
      fontSize: 3.75em,
      lineHeight: 1.1,
      height: 460px
    ),
    horizontal: (
      height: 130px
    )
  ),
  breakpoint: (
    medium: em(915px)
  )
);

.callout-block {
  color: white;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.-image {
    &:not(.-grey):before {
      @include transition(background-color);
      background-image: linear-gradient(to bottom, transparent, transparent, #072973);
      content: '';
      display: block;
      height: 100%;
      opacity: .7;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }

    &:hover {
      .callout-block__content {
        height: 100%;
      }
    }

    &.-grey {
      .callout-block__image {
        top: 90px;
      }

      &:hover {
        .callout-block__content {
          height: 100%;
        }
      }
    }

    .callout-block__image {
      display: block;
      height: auto;
      min-width: 100%;
      position: absolute;
      top: 35px;
      z-index: 0;
    }
  }

  &.-color {
    &.-green {
      background-color: color(green);
    }

    &.-black {
      background-color: color(gray, xdark);
    }
  }

  // Sizes
  &.-small {
    height: setting('sizes.small.height', $calloutBlock);
  }

  &.-medium {
    height: setting('sizes.medium.height', $calloutBlock);

    p {
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &.-large {
    border: 25px solid white;
    height: setting('sizes.large.height', $calloutBlock) - 100px;

    @include breakpoint(setting('breakpoint.medium')) {
      height: setting('sizes.large.height', $calloutBlock);
    }
  }

  &.-horizontal {
    height: setting('sizes.medium.height', $calloutBlock);
    margin-bottom: 0;

    @include breakpoint(setting('breakpoint.medium', $calloutBlock)) {
      height: setting('sizes.horizontal.height', $calloutBlock);

      .callout-block__content__label {
        display: block;
        float: left;
        line-height: 1.3;
        margin: 0;
        width: 20%;
      }

      .callout-block__content__headline {
        display: block;
        float: left;
        width: 65%;
      }

      .callout-block__content__button {
        display: block;
        float: right;
        margin-top: 5px;
        width: 15%;
      }
    }
  }
}

.callout-block__content {

  .callout-block.-image & {
    @include transition(height);
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background-color: color(blue);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    @include clearfix;
    text-align: center;
  }

  .callout-block.-image.-grey & {
    background-color: color(grey, xlight);
    height: 90px;
  }

  .callout-block.-button & {
    height: 100%;
    background-color: color(grey, xlight);
    border-bottom: 14px solid color(orange);
    padding: setting('gutterPaddingSmall');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    .callout-block__content__button {
      margin-top: auto;
    }

    img {
      max-height: 126px;
    }
  }

  .callout-block.-color & {
    padding: 0 25% 0 15%;
  }

  .callout-block.-horizontal & {
    padding: 0 7%;
  }
}

.callout-block__content__headline {
  font-family: $openSans;

  .callout-block.-image & {
    color: white;
    margin: 10px auto;
  }

  .callout-block.-image.-grey & {
    color: color(blue);
    font-size: 1.75em;
    margin: 10px auto;
  }

  .-button & {
    padding-top: setting('gutterPaddingSmall');
  }

  .callout-block.-color & {
    margin-bottom: .25em;
  }

  .callout-block.-small & {
    font-size: setting('sizes.small.fontSize', $calloutBlock);
    line-height: setting('sizes.small.lineHeight', $calloutBlock);
  }

  .callout-block.-medium & {
    font-family: $openSansCondensed;
    font-size: setting('sizes.medium.fontSize', $calloutBlock);
    line-height: setting('sizes.medium.lineHeight', $calloutBlock);
  }

  .callout-block.-large & {
    font-size: setting('sizes.large.fontSize', $calloutBlock) - 1em;
    line-height: setting('sizes.large.lineHeight', $calloutBlock);

    @include breakpoint(setting('breakpoint.large')) {
      font-size: setting('sizes.large.fontSize', $calloutBlock);
    }
  }

  .callout-block.-horizontal & {
    font-size: setting('sizes.medium.fontSize', $calloutBlock);
    line-height: setting('sizes.medium.lineHeight', $calloutBlock);

    @include breakpoint(setting('breakpoint.medium', $calloutBlock)) {
      font-size: 2em;
      margin-bottom: 0;
    }
  }
}