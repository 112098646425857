.container {
  max-width: setting('container', $default-layout);

  @include breakpoint(setting('container', $default-layout)) {
    margin-left: auto;
    margin-right: auto;
  }

    &.-full {
    max-width: none;
  }

  &.-gray {
    background-color: color(gray, xlight);
  }
}
